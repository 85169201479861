import React from "react";
import { Layout } from "../layouts";

const NewsPage = (props) => (
  <Layout location={props.location}>
    <div className="content-wrap">
      <h1 className="content-h2">Aktuelles</h1>

      <p>
        Die Praxis ist vom 21.02. bis zum 28.02.25 geschlossen.
        <br />
        <br />
        Vertretung in dringenden Fällen: vom 21.02. bis zum 25.02.25:
        <br />
        Dr. Alexander Beck, Mathystraße 13, 76133 Karlsruhe Tel 07 21 / 37 60 60
        <br />
        <br />
        vom 26.02. bis zum 28.02.25:
        <br />
        Drs. Fettig/Tabertshofer, Pfinztalstr. 7, Tel. 0721/405533
        <br />
        <br />
      </p>
      <br />
    </div>
  </Layout>
);

export default NewsPage;
